import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DxpComponentService } from 'src/utils/provider/dxp.component.service';
import { AuthService, Store } from '../../providers';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public dxpComponentService: DxpComponentService,
    public store: Store,
    private router: Router, private auth: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      this.auth.getCurrentUser().then(currentUser => {
        if (currentUser) this.initStencil(currentUser);
        resolve(true);
      },
        (error) => {
          this.router.navigate(['signin']);
          resolve(false);
        });
    });
  }

  initStencil(currentUser) {
    if (this.dxpComponentService.isChangeUser) {
      this.store.getStoreById(currentUser.StoreID).subscribe(store => {
        this.dxpComponentService.initializeDxpComponent({ user: currentUser, store, cre: this.auth.getAuthToken(), currentShoppingList: null });
      });
    };
  }
}
