import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { tap } from 'rxjs/operators';
import { ENV } from 'src/environments/environment';
import { AppSettings, AuthService, Store, Utils } from 'src/providers';
import { ProductGatewayService as Product } from '../../product-services.module';
import _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'page-product-detail',
	templateUrl: 'product-detail.html',
	styleUrls: ['product-detail.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ProductDetailPage {
	product: any;
	productV: any
	depts: any;
	favorList: any;
	favItems: any;
	sListItems: any;
	cartItems: any;
	defaultImg = ENV.DefaultImg;
	defaultImageSaleTagUrl = '';
	enableScan = false;
	showAB = false;
	showUPC = false;
	showSale = true;
	showD = false;
	showHealth = false;

	coupon: any;
	type: any;
	isSelectCoupon = false;

	haveChange: boolean = false;
	public modalId: string;
	public evtSubcribe: any;
	public isModal = true;
	public upcParam: string = null;

	currentUser: any;
	currentStore: any;

	public expand: boolean = false;

	constructor(
		public route: ActivatedRoute,
		public modalCtrl: ModalController,
		public utils: Utils,
		public products: Product,
		private auth: AuthService,
		private store: Store,
		public sanitizer: DomSanitizer,
		public appSetting: AppSettings,
		public events?: NgxPubSubService,
	) {
		const params = this.route.snapshot.paramMap;
		this.coupon = params.get('coupon');
		this.type = params.get('type') || 'coupon';
		this.evtSubcribe = this.events && this.events.subscribe('close-modal', () => {
			this.onDismiss();
		}) || null;
		this.upcParam = params.get('upc');
		if (this.upcParam) {
			this.isModal = false;
			let upc = params.get('upc');
			this.product = {
				Sku: upc,
				UPC: upc
			};
		}
	}

	onDismiss() {
		this.modalCtrl.dismiss({ haveChange: this.haveChange }, null, this.modalId);
	}

	ionViewDidEnter() {
		this.loadData();
		this.modalCtrl.getTop().then(modalElement => {
			this.modalId = modalElement != null ? modalElement.id : null;
		});
		this.loadDefaultImageSaleTagUrl();
	}
	
	loadDefaultImageSaleTagUrl() {
		this.appSetting.getSaleTagImage().subscribe(imageUrl => {
			this.defaultImageSaleTagUrl = imageUrl;
			console.log("this.defaultImageSaleTagUrl", this.defaultImageSaleTagUrl);
		});
	}

	loadUserStore() {
		this.auth.getCurrentUser().then((currentUser) => {
			console.log('currentUser myAccount', currentUser);
			this.currentUser = currentUser;
			this.store.getStoreById(currentUser.StoreID).subscribe((store: any) => {
				this.currentStore = store;
			}, (error) => {
			});
		}, (error) => {
		});
	}

	async loadData() {
		let product = this.product;
		let productV = product;
		this.productV = productV;
		this.utils.showLoading();
		if (!productV.isLoaded) {
			await this.loadProductVariantDetail(productV);
		}
		if (!this.currentUser) {
			this.utils.hideLoading();
			return;
		}
		this.utils.hideLoading();
		// load Health Focus of mi9
		if (productV.nutri === undefined) {
			this.products.getHealth(this.product).subscribe((nutri) => {
				if (!nutri) {
					return;
				}
				productV.health = this.sanitizer.bypassSecurityTrustHtml(nutri);
			});
		}
	}

	public async loadProductVariantDetail(productV) {
		const upc = productV.Sku || productV.UPC;
		const pvs: any = await this.products.getProductByUPC(null, 0, 0, [upc]).toPromise();
		if (!pvs || !pvs.Products || pvs.Products.length === 0) {
			return;
		}
		Object.assign(productV, pvs.Products[0]);
		// get Cat Name
		await this.getDeptName(productV);
		productV.isLoaded = true;

		//handle sale note
		if(this.productV.UnitPrice && this.productV.UnitPrice.toLowerCase().includes('sale-note')) {
			let unitPriceSplit = this.productV.UnitPrice.split("<br>");
			this.productV.UnitPrice = unitPriceSplit[0].trim();
			this.productV.SalePriceText += ` <br>${unitPriceSplit[1]}`;
		}
	}

	public async getDeptName(product) {
		if (product.RSCategoryName) {
			return product.RSCategoryName;
		}
		if (!product.Category) {
			product.RSCategoryName = '';
			return product.RSCategoryName;
		}
		let depts = this.depts || await this.loadDepts().toPromise();
		if (!depts) {
			return '';
		}
		let dept = _.find(depts, { ExternalID: product.Category });
		if (dept) {
			product.RSCategoryName = dept.CategoryName;
			product.RSCategory = dept.CS_CategoryId;
		}
	}

	public loadDepts() {
		let o = this.products.getDepartments().pipe(
			tap((depts) => {
				this.depts = depts;
			})
		)
		return o;
	}

	closeModal() {
		this.modalCtrl.dismiss(this.product);
	}

	errorImgHandler(event) {
		event.target.src = "assets/imgs/DefaultMissingImage.jpeg";
	}
	
	expandDisclaimer() {
		this.expand = !this.expand;
	}
}