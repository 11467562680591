import { Api } from '../api/api';
import 'rxjs/add/operator/toPromise';

import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs/Observer';

import * as _ from 'lodash';
import { ENV } from 'src/environments/environment';

@Injectable()
export class User {
	dxpApiUrl: any;

	constructor(public api: Api, private cache: CacheService) {
		this.dxpApiUrl = 'dxp/api';
	}

	login(accountInfo: any) {
		return new Promise((resolve, reject) => {
			this.api.post(`${this.dxpApiUrl}/login/${accountInfo.username}`, '"' + accountInfo.password + '"').subscribe((data: any) => {
				resolve(data);
			},
				(error) => {
					console.error('ERROR', error);
					reject(error);
				});
		});
	}

	loginCard(cardNumber) {
		return new Promise((resolve, reject) => {
			this.api.post(`${this.dxpApiUrl}/logincard/${cardNumber}`, null).subscribe((data: any) => {
				resolve(data);
			},
				(error) => {
					console.error('ERROR', error);
					reject(error);
				});
		});
	}

	loginByPhone(phone, lastName) {
		return new Promise((resolve, reject) => {
			this.api.post(`${this.dxpApiUrl}/loginbyphone/${phone}?firstName=&lastName=${lastName}`, null).subscribe((data: any) => {
				resolve(data);
			},
				(error) => {
					console.error('ERROR', error);
					reject(error);
				});
		});
	}

	checkExistUsername(username) {
		// return new Promise((resolve, reject) => {
		// 	this.api.get(`users/${username}/exist`, null, null, false, true).subscribe((result: any) => {
		// 		console.log('exist username', result);
		// 		resolve(result);
		// 	},
		// 		(error) => {
		// 			console.log('ERROR', error);
		// 			reject(error);
		// 		});
		// });
		return this.api.get(`${this.dxpApiUrl}/users/${username}/exist`, null, null, false, true);
	}

	checkExistEmail(email) {
		// return new Promise((resolve, reject) => {
		// 	this.api.get(`users/email/${email}`, null, null, false, true).subscribe((result: any) => {
		// 		console.log('exist password', result);
		// 		resolve(result);
		// 	},
		// 		(error) => {
		// 			console.log('ERROR', error);
		// 			reject(error);
		// 		});
		// });
		return this.api.get(`${this.dxpApiUrl}/users/email/${email}`, null, null, false, true);
	}

	searchUsersByField(field, val) {
		return this.api.get(`${this.dxpApiUrl}/users?field=${field}&value=${val}`, null, null, false, true);
	}

	checkDemoGraphic(cardNumber) {
		return new Promise((resolve, reject) => {
			this.api.get(`${this.dxpApiUrl}/cards/${cardNumber}/demographic`, null, null, false, true).subscribe((result: any) => {
				console.log('Check Demo Graphic', result);
				resolve(result);
			},
				(error) => {
					console.log('ERROR', error);
					reject(error);
				});
		});
	}

	checkDemographicInfo(cardId, lastName) {
		return this.api.get(`${this.dxpApiUrl}/cards/checkcardinfo?cardId=${cardId}&lastName=${lastName}`, null, null, false, true);
	}

	checkDemographicInfoByPhone(phoneNumber, lastName) {
		return this.api.get(`${this.dxpApiUrl}/cards/checkcardinfo/byphone?phoneNumber=${phoneNumber}&lastName=${lastName}`, null, null, false, true);
	}

	checkCardIsUsed(customerID) {
		return this.api.get(`${this.dxpApiUrl}/users?field=loyaltycardid&value=${customerID}`, null, null, false, true);
	}

	signup(accountInfo: any) {
		let newAccount = {
			Email: accountInfo.email,
			UserEmail: accountInfo.email,
			Password: accountInfo.password,
			FirstName: _.upperFirst(_.toLower(accountInfo.firstName)),
			MiddleName: _.upperFirst(_.toLower(accountInfo.middleName)),
			LastName: _.upperFirst(_.toLower(accountInfo.lastName)),
			Address: accountInfo.address,
			Address2: accountInfo.address2,
			City: accountInfo.city,
			State: accountInfo.state,
			ZipCode: accountInfo.zipCode,
			Phone: accountInfo.phone,
			ReceiveEmail: accountInfo.cbReceiveEmails,
			ReceiveTextMessage: accountInfo.cbReceiveTextMessage,
			LoyaltyAutoEnroll: accountInfo.LoyaltyAutoEnroll,
			StoreID: accountInfo.storeID,
			Promocode: accountInfo.promoCode,
			CellPhone: accountInfo.cbIsMobile ? accountInfo.phone : null,
			ExternalCustomerCardID: accountInfo.ExternalCustomerCardID,
			SRCardID: accountInfo.LoyaltyCardID,
			AccountType: accountInfo.AccountType,
			BirthDay: accountInfo.BirthDay,
			DriverLicenseNumber: accountInfo.DriverLicenseNumber
		}

		return this.api.post(`${this.dxpApiUrl}/users`, newAccount);
	}

	updateCommunication(username, general: any) {
		return new Promise((resolve, reject) => {
			this.api.put(`${this.dxpApiUrl}/users/communication/${username}`, general).subscribe((result: any) => {
				console.log('Update Communication', result);
				resolve(result);
			},
				(error) => {
					console.log('ERROR', error);
					reject(error);
				});
		});
	};

	updateUser(user: any) {
		return new Promise((resolve, reject) => {
			this.api.put(`${this.dxpApiUrl}/users/${user.UserID}`, user).subscribe((result: any) => {
				console.log('Update User', result);
				resolve(result);
			},
				(error) => {
					console.log('ERROR', error);
					reject(error);
				});
		});
	}

	updatePassword(uid, opwd, npwd) {
		return new Promise((resolve, reject) => {
			this.api.put(`${this.dxpApiUrl}/users/${uid}/ChangePassword`, {
				Email: uid,
				OldPassword: opwd,
				NewPassword: npwd
			}).subscribe((result: any) => {
				console.log('Update Password', result);
				resolve(result);
			},
				(error) => {
					console.log('ERROR', error);
					reject(error);
				});
		});
	}

	updateUserName(currentUsername, newUsername, pwd) {
		return new Promise((resolve, reject) => {
			this.api.post(`${this.dxpApiUrl}/users/change-username/v5?username=${currentUsername}&newUsername=${newUsername}`, `"${pwd}"`).subscribe((result: any) => {
				console.log('Update UserName', result);
				resolve(result);
			},
				(error) => {
					console.log('ERROR', error);
					reject(error);
				});
		});
	}

	getUserByUsername(username) {
		return new Promise((resolve, reject) => {
			this.api.get(`${this.dxpApiUrl}/users?un=${username}`, null, null, false, true).subscribe((result: any) => {
				console.log('RESULT USER: ', result);
				resolve(result);
			},
				(error) => {
					console.log('ERROR', error);
					reject(error);
				});
		});
	}

	updateProfile(username, profile, value) {
		return new Promise((resolve, reject) => {
			this.api.put(`${this.dxpApiUrl}/profile/${profile}?username=${username}`, `"${value}"`).subscribe((result: any) => {
				console.log('Update Profile', result);
				resolve(result);
			},
				(error) => {
					console.log('ERROR', error);
					reject(error);
				});
		});
	}

	changePassword(username, pwd) {
		return new Promise((resolve, reject) => {
			this.api.post(`${this.dxpApiUrl}/users/set-password?username=${username}`, `"${pwd}"`).subscribe((result: any) => {
				console.log('Change Password', result);
				resolve(result);
			},
				(error) => {
					console.log('ERROR', error);
					reject(error);
				});
		});
	}

	receivePhysicalCard(CardId) {
		var object = {
			BannerId: ENV.DefaultBanerId,
			CardId: CardId
		};
		return new Promise((resolve, reject) => {
			this.api.post(`${this.dxpApiUrl}/cards/physical-card`, object).subscribe((result: any) => {
				console.log('Receive Physical Card', result);
				resolve(result);
			},
				(error) => {
					console.log('ERROR', error);
					reject(error);
				});
		});
	}

	GetContactByDriverLicense(text) {
		return new Promise((resolve, reject) => {
			this.api.post(`${this.dxpApiUrl}/contact/find-by-driver-license`, `"${text}"`).subscribe((result: any) => {
				console.log('GetContactByDriverLicense', result);
				resolve(result);
			},
				(error) => {
					console.log('ERROR', error);
					reject(error);
				});
		});
	}

	getListSegment(loyaltyCardId) {
		return new Promise((resolve, reject) => {
			this.api.get(`${this.dxpApiUrl}/segment/get?loyaltyCardId=${loyaltyCardId}`, null, null, false, true).subscribe((result: any) => {
				resolve(result);
			},
				(error) => {
					console.log('ERROR', error);
					reject(error);
				});
		});
	}

	updateSegment(username, segment, isHave = false) {
		return new Promise((resolve, reject) => {
			this.api.post(`${this.dxpApiUrl}/segment/update?username=${username}&segment=${segment}&isHave=${isHave}`, null).subscribe((result: any) => {
				resolve(result);
			},
				(error) => {
					console.log('ERROR', error);
					reject(error);
				});
		});
	}

	getInterestClub(username) {
		return this.api.get(`${this.dxpApiUrl}/profile/InterestClub?username=${username}`);
	}

	updateInterestClub(username, pets) {
		let params = JSON.stringify(pets);
		params = params.replace(/\"/g, '\\"');
		console.log('updateInterestClub params', params);
		return this.api.put(`${this.dxpApiUrl}/profile/InterestClub?username=${username}`, '"' + params + '"');
	}

	getAddresses(userId) {
		return this.api.get(`${this.dxpApiUrl}/users/contacts/user?userId=${userId}`, null, null, false, true);
	}

	getAddressesById(contactId) {
		return this.api.get(`${this.dxpApiUrl}/users/contact?contactId=${contactId}`);
	}

	removeAddressById(contactId) {
		return this.api.get(`${this.dxpApiUrl}/users/contact/delete?contactId=${contactId}`);
	}

	updateAddress(address) {
		let body = {
			CS_ContactID: address.CS_ContactID,
			Address: address.Address,
			City: address.City,
			Country: null,
			Email: null,
			ExternalId: null,
			Fax: null,
			FirstName: address.FirstName,
			LastName: address.LastName,
			MiddleName: address.MiddleName,
			ReceiveTextMessage: address.ReceiveTextMessage,
			MobilePhone: address.MobilePhone,
			Note: null,
			OrderNumber: null,
			Phone: null,
			SecondaryPhone: address.SecondaryPhone || null,
			State: address.State,
			Title: null,
			UserID: address.UserID,
			ZipCode: address.ZipCode,
			IsBilling: address.IsBilling,
			IsDelivery: address.IsDelivery,
			ExternalId2: address.ExternalId2
		}
		return this.api.post(`${this.dxpApiUrl}/users/contact/update`, body);
	}

	addAddress(address) {
		let body = {
			CS_ContactID: null,
			Address: address.Address,
			City: address.City,
			Country: null,
			Email: null,
			ExternalId: null,
			Fax: null,
			FirstName: address.FirstName,
			LastName: address.LastName,
			MiddleName: address.MiddleName,
			ReceiveTextMessage: address.ReceiveTextMessage,
			MobilePhone: address.MobilePhone,
			Note: null,
			OrderNumber: null,
			Phone: null,
			SecondaryPhone: null,
			State: address.State,
			Title: null,
			UserID: address.UserID,
			ZipCode: address.ZipCode,
			IsBilling: address.IsBilling,
			IsDelivery: address.IsDelivery
		}
		return this.api.post(`${this.dxpApiUrl}/users/contact/add`, body);
	}

	getUser(username, resetCache?) {
		return this.api.get(`${this.dxpApiUrl}/users?un=${username}`, null, null, resetCache);
	}
}
