import { Utils } from '../../providers';
import { Api } from '../api/api';
import 'rxjs/add/operator/toPromise';

import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs/Observer';
import * as _ from 'lodash';
import { ENV } from '../../environments/environment';

@Injectable()
export class ShoppingList {
	dxpApiUrl: any;
	
	constructor(public api: Api, private cache: CacheService, public utils: Utils) {
		this.dxpApiUrl = 'dxp/api';
	}

	getActiveShoppingList(user) {
		const self = this;
		return new Observable((obs: Observer<any>) => {
			self.api.get(`${this.dxpApiUrl}/shoppinglists/all?username=${user.Email}`).subscribe(shoppinglists => {
				const activedShoppingList = _.find(shoppinglists, {
					IsActive: true
				});
				obs.next(activedShoppingList);
				obs.complete();
			});
		});
	}

	addOfferToCard(offer, user, shoppingList) {
		const param = {
			'couponId': offer.Id,
			'couponType': offer.CpnType,
			'listId': shoppingList.CS_UserShoppingListId,
			'provider': '',
			'deviceId': '',
			'catID': offer.Cats && offer.Cats[0] && offer.Cats[0].CS_CategoryId || '',
			'quantity': offer.ItmQty,
			'bannerID': ENV.DefaultBanerId
		};
		return this.api.post(`${this.dxpApiUrl}/cards/${user.SRCardID}/coupon/v4?${this.utils.encodeUrl(param)}`, {});
	}

	addCouponToCard(digitalCoupon, user) {
		const param = {
			'bannerId': ENV.DefaultBanerId,
			'username': user.Email,
			'cpnId': digitalCoupon.ID
		};
		return this.api.post(`${this.dxpApiUrl}/mfr-digital-coupons/v4/clipped?${this.utils.encodeUrl(param)}`, {});
	}
}
