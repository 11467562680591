import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { NavController, ModalController, NavParams } from '@ionic/angular';
import { AuthService, Utils } from '../../../providers';
import { ShoppingList } from '../../../providers/shopping-list/shopping-list';
import { MessagePopup } from '../message-popup/message-popup';
import { DigitalCoupon } from 'src/providers/digital-coupon/digital-coupon';

@Component({
  selector: 'app-digital-coupon-detail-popup',
  templateUrl: 'digital-coupon-detail-popup.html',
  styleUrls: ['./digital-coupon-detail-popup.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DigitalCouponDetailPopup implements OnInit {


  @Input() digitalCoupon: any;
  @Input() type: any;
  currentUser: any;
  btnload : boolean = false;
  public expand: boolean = false;
  public upcs: any = [];

  constructor(
    private nav: NavController,
    private modalCtrl: ModalController,
    public shoppingList: ShoppingList,
    private auth: AuthService,
    public utils: Utils,
    public digitalCouponService: DigitalCoupon,
  ) {
  }

  ngOnInit() {

  }

  async ionViewWillEnter() {
    let cUser = await this.auth.getCurrentUser();
    this.currentUser = cUser
    this.digitalCoupon = JSON.parse(this.digitalCoupon);
    await this.LoadData();
    this.getParticipatingProducts();
  }

  async LoadData(){
    if(this.digitalCoupon && this.currentUser){
      try{
        let {ID} = this.digitalCoupon;
        let {Clipped} = this.digitalCoupon
        this.btnload = Clipped

        if(ID && this.currentUser){
          let rs = await this.digitalCouponService.getDealDetailById(ID , this.currentUser).toPromise();
          if(rs){
            this.digitalCoupon = {... rs , Upcs : rs["Upcs"]}
          }
        }
      }catch(err){
      }
    }
  }

  async getParticipatingProducts() {
    if (this.digitalCoupon.Upcs) {
      this.upcs = this.digitalCoupon.Upcs;
      return;
    }
  }

  expandDisclaimer() {
    this.expand = !this.expand;
  }

  addToCard(digitalCoupon) {
    this.utils.showLoading();
    this.auth.getCurrentUser().then(result => {
      this.currentUser = result;
      this.shoppingList.addCouponToCard(digitalCoupon, this.currentUser).subscribe(async result => {
        this.utils.hideLoading();
        if (result) {
          digitalCoupon.Clipped = true;
          this.btnload = digitalCoupon.Clipped
          console.log('btnload', this.btnload);
          // this.modalCtrl.dismiss(this.digitalCoupon);
        } else {
          const modal: HTMLIonModalElement = await this.modalCtrl.create({
            component: MessagePopup,
            componentProps: {
              title: 'ADD TO ACCOUNT ERROR',
              message: 'This coupon is already added to your account.',
            },
            // tslint:disable-next-line:quotemark
            cssClass: "message-popup"
          });
          await modal.present();
        }
      }, async (error) => {
        console.log('addCouponToCard error', error);
        const modal: HTMLIonModalElement = await this.modalCtrl.create({
          component: MessagePopup,
          componentProps: {
            title: 'ADD ERROR',
            message: error.statusText,
          },
          // tslint:disable-next-line:quotemark
          cssClass: "message-popup"
        });
        await modal.present();
      });
    });
  }


  closeModal() {
    this.modalCtrl.dismiss(this.digitalCoupon);
  }

}
