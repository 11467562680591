import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
// import { MomentModule } from 'ngx-moment';

import { Product } from './providers/product.service';
import { ProductGatewayService } from './providers/product-gateway.service';
import { OnlineProductService } from './providers/online-product.service';
import { RSProductService } from './providers/rs-product.service';
import { RelationshopEComHttpClient } from 'src/providers/api/rs-ecom-api.service';
import { RelationshopHttpClient } from 'src/providers/api/rs-api.service';
export {
	Product,
	ProductGatewayService,
	OnlineProductService,
};

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		// MomentModule,
	],
	entryComponents: [],
	exports: [],
	declarations: [],
	providers: [Product, OnlineProductService, ProductGatewayService, RSProductService, RelationshopEComHttpClient, RelationshopHttpClient]
})
export class ProductServiceModule {

}