import { Injectable, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Injectable({
	providedIn: 'root'
})
@Pipe({name: 'safeResourceUrl'})
export class SafeResourceUrlPipe {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
