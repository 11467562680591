import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { NavController, ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-terms-conditions-popup',
  templateUrl: 'terms-conditions-popup.html',
  styleUrls: ['./terms-conditions-popup.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TermsConditionsPopup implements OnInit {

  constructor(
    private modalCtrl: ModalController,
  ) {
  }

  ngOnInit() {

  }

  ionViewWillEnter() {
  }


  closeModal() {
    this.modalCtrl.dismiss();
  }

}
