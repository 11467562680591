import { ProductModule } from './../../product/product.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { OfferDetailPopup } from './offer-detail-popup';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: OfferDetailPopup
      }
    ]),
    ProductModule
  ],
  declarations: [OfferDetailPopup]
})
export class OfferDetailPopupModule {}
