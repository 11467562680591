import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, IonInfiniteScroll } from '@ionic/angular';
import { combineLatest, Observable } from 'rxjs';
import { AuthService, Store, Utils } from 'src/providers';
import { ProductDetailPage } from '../product.module';


export abstract class BaseProductsPage {
  public savedRecipeIds: any = [];
  sListItems: any;
  favorList: any;
  favItems: any;
  cartItems: any;
  depts: any;
  /** paging*/
  cPos = 0;
  size = 8;
  maxPos;
  /** paging **/
  isBlockLoading;
  public cri = {
    keyword: '',
    deptIds: [],
    brandIds: [],
    tags: [],
    customFields: [],
    // start: this.cPos,
    // max: this.size,
    pageSize: this.size,
    pageIndex: -1
  }
  @ViewChild(IonInfiniteScroll, { read: true }) infiniteScroll: IonInfiniteScroll;

  currentUser: any;
  currentStore: any;


  constructor(
    public auth: AuthService,
    public store: Store,
    public modalCtrl: ModalController,
    public utils: Utils
  ) {
    this.auth.getCurrentUser().then((currentUser) => {
      console.log('currentUser myAccount', currentUser);
      this.currentUser = currentUser;
      this.store.getStoreById(currentUser.StoreID).subscribe((store: any) => {
        this.currentStore = store;
      }, (error) => {
      });
    }, (error) => {
    });
  }

  public abstract loadData(): Observable<any>;// product array
  public abstract loadMore(): Observable<any>;// product array
  public abstract resetResult(): void;

  async ionViewDidEnter() {
    // start reset
    this.resetResult();
    Object.assign(this, {
      sListItems: null,
      cartItems: null,
      favorList: null,
      favItems: null,
      depts: [],
      maxPos: undefined,
      cPos: 0
    });
    if (this.infiniteScroll) {
      this.infiniteScroll.disabled = false;
    }
    // end reset
    await this.utils.showLoading();
    this.store = this.currentStore;
    this.mainLoad().subscribe(() => {
      this.utils.hideLoading();
    }, async error => {
      await this.utils.hideLoading();
      await this.utils.alertError(this.utils.offlineMsg);
    });
  }

  public mainLoad(): Observable<any> {
    const dataO = this.loadData();
    let oArray = [dataO];
    return combineLatest(oArray);
  }

  public async showDetail(product) {
    const modal: HTMLIonModalElement = await this.modalCtrl.create({
      component: ProductDetailPage,
      componentProps: {
        product: product,
        depts: this.depts,
        sListItems: this.sListItems,
        favorList: this.favorList,
        favItems: this.favItems,
        cartItems: this.cartItems

      },
      cssClass: "product-detail-popup"
    });
    await modal.present();
    const rs = await modal.onDidDismiss();
    // console.log(rs);
  }

  public mainLoadMore(event?) {
    this.loadMore().subscribe(() => {
      if (!event) {
        return;
      }
      event.target.complete();
      if (this.cPos >= this.maxPos) {
        event.target.disabled = true;
      }
    });
  }
}