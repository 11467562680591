import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { NavController, ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-privacy-policy-popup',
  templateUrl: 'privacy-policy-popup.html',
  styleUrls: ['./privacy-policy-popup.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrivacyPolicyPopup implements OnInit {

  constructor(
    private modalCtrl: ModalController,
  ) {
  }

  ngOnInit() {

  }

  ionViewWillEnter() {
  }


  closeModal() {
    this.modalCtrl.dismiss();
  }

}
