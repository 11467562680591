import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseProductsPage } from '../../pages/base-products-page';
import { ProductGatewayService as Product } from '../../product-services.module';
import _ from 'lodash';
import { AuthService, Store, Utils } from 'src/providers';
import { ModalController } from '@ionic/angular';
function padLeadingZeros(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}
@Component({
	selector: 'product-list',
	templateUrl: 'product-list.html',
	styleUrls: ['product-list.scss']
})
export class ProductList extends BaseProductsPage implements OnInit, OnChanges {
	@Input() upcs: Array<any>;
	@Input() imageTagUrl: string;

	products: Array<any>;
	title: string = 'Products';
	dataLoaded: boolean = false;
	size = 50;
	upcList: any[];

	constructor(
		public auth: AuthService,
		public store: Store,
		public utils: Utils,
		public modalCtrl: ModalController,
		public product: Product,
	) {
		super(auth, store, modalCtrl, utils);
	}

	ngOnInit(): void {

	}

	ngOnChanges(changes: SimpleChanges) {
		this.ionViewDidEnter();
	}

	loadData() {
		this.maxPos = this.upcs.length;
		return this.loadMore();
	}

	loadMore() {
		if (this.maxPos !== undefined && this.cPos >= this.maxPos) {
			return of(null);
		};
		let newCPos = Math.min(this.cPos + this.size, this.maxPos === undefined && Number.MAX_VALUE || this.maxPos);
		this.upcList = this.upcs.slice(this.cPos, newCPos);
		let o = this.product.getProductsByIds(this.upcList).pipe(
			switchMap(rs => {
				if (!rs && newCPos < this.maxPos) {
					if (rs == undefined) {
						//retry get fresh data or jump next page index
						this.cPos = newCPos; 
					}
					return this.loadMore();
				} else {
					return of(rs);
				}
			}),
			map((rs: any) => {
				if (rs) {
					this.cPos = newCPos;
					if (!this.products) {
						this.products = [];
					}
					// assign reward fron parent context
					// if (this.reward) {
					// 	rs.Products.map((item: any) => {
					// 		return item.reward = this.reward;
					// 	});
					// }
					// assign imageTagUrl from parent context
					if (this.imageTagUrl) {
						rs.Products.map((item: any) => {
							return item.ImageTagUrl = this.imageTagUrl;
						});
					}
					let productsSorted = [];
					let productsGroup = _.groupBy(rs.Products, 'UPC');
					_.forEach(this.upcList, upc => {
						upc = padLeadingZeros(upc, 14);
						if (upc && productsGroup[upc]) {
							Array.prototype.push.apply(productsSorted, productsGroup[upc] || []);
						}
					})
					Array.prototype.push.apply(this.products, productsSorted || []);
					console.log("this.products", this.products);
					return productsSorted;
				}
				console.log("this.products", this.products);
				return this.products;
				//test
			})
		)
		return o;
	}

	resetResult() { };
}