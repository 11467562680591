import { Api } from '../api/api';
import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { Store, AuthService } from '../../providers';
import { throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
@Injectable()
export class Offer {
	dxpApiUrl: any;
	currentUser: any;
	currentStore: any;

	constructor(public api: Api, private cache: CacheService, private store: Store, private auth: AuthService,) {
		this.dxpApiUrl = 'dxp/api';
	}

	getAllOffer(user, includedExpired, programCode, refresher = true) {
		const self = this;
		return this.store.getStoreById(user.StoreID).pipe(
			switchMap((store) => {
				const storeCode = store.StoreID;
				var reqUrl = `${this.dxpApiUrl}/offer/GetAvailableOffers?customerCode=${user.SRCardID}&storeId=${storeCode}&cardId=${user.ExternalCustomerCardID}&programCode=${programCode}`;
				return self.api.get(reqUrl, null, null, false, true);
			}),
			catchError(error => {
				return throwError(error);
			})
		);
	}

	addPromoCode(user, promoCode) {
		const self = this;
		const req = self.api.post(`${this.dxpApiUrl}/coupon/click2card/promoCode/v4?promoCode=${promoCode}&cardId=${user.SRCardID}`, {});
		return req;
	}

	validatePromoCode(promoCode, storeId = 1, bannerId = 30) {
		const self = this;
		const req = self.api.get(`${this.dxpApiUrl}/coupon/by/promocode/${promoCode}/store/${storeId}/banner/${bannerId}?isNewUser=true`, null, null, false, true);
		return req;
	}

	getFuelGas(user, memberAccount: string, includeTrans: boolean) {
		const self = this;
		const req = self.api.get(`${this.dxpApiUrl}/cards/${user.SRCardID}/point?memberAccount=${memberAccount}&includedTrans=${includeTrans}`);
		return req;
	}
}
