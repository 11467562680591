import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from 'src/providers/utils/pipes/safe-html/safe-html';

@NgModule({
  imports: [
    CommonModule
  ],
	declarations: [SafeHtmlPipe],
  exports: [SafeHtmlPipe],
	
})
export class ShareModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ShareModule,
      providers: [ SafeHtmlPipe ]
    };
  }
 }
