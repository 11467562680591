import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'truncate'
})

export class TruncatePipe implements PipeTransform {
	transform(text, args): any[] {
		let max = args;
		if (text && text.length > args) {
			text = text.substr(0, max - 3) + "...";
		}
		return text;
	}
}
