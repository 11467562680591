import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Storage } from '@ionic/storage';
const maxAge = 3000000; // 50 min
@Injectable()
export class RequestCache {

    constructor(private storage: Storage) { }
    async get(req: HttpRequest<any>): Promise<HttpResponse<any> | undefined> {
        const url = req.urlWithParams;
        let cached = await this.storage.get(url);

        if (!cached) {
            return undefined;
        }
        try {
            cached = JSON.parse(cached);
            const isExpired = cached.lastRead < (Date.now() - maxAge);
            return isExpired ? undefined : cached.response;
        } catch (error) {
            this.delete(req);
            return undefined;
        }
    }

    put(req: HttpRequest<any>, response: HttpResponse<any>): void {
        // console.log('cache put', req, response);
        // console.log('cache ', JSON.parse(JSON.stringify(this.cache)));

        const url = req.urlWithParams;
        const entry = { url, response, lastRead: Date.now() };
        this.storage.set(url, JSON.stringify(entry));
        // hanlde delete cache expired
        // const expired = Date.now() - maxAge;
        // this.storage.forEach(expiredEntry => {
        //     if (expiredEntry.lastRead < expired) {
        //         this.storage.remove(expiredEntry.url);
        //     }
        // });
    }
    delete(req: HttpRequest<any>) {
        this.storage.remove(req.urlWithParams);
    }
}
