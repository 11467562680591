import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService, Store } from "src/providers";
import { IProductService } from "./iproduct.service";
import { OnlineProductService } from "./online-product.service";
import { Product } from "./product.service";
import { RSProductService } from "./rs-product.service";
import { map, tap, switchMap } from "rxjs/operators";

@Injectable()
export class ProductGatewayService implements IProductService {
    public api: IProductService;

    currentUser: any;
    currentStore: any;

    constructor(
        private auth: AuthService,
        private store: Store,
        public onlineProductApi: OnlineProductService,
        public instoreProductApi: Product,
        public RSProductApi: RSProductService,
    ) {
        this.api = this.RSProductApi;
        /*
        this.auth.currentUser$.asObservable().pipe(
            switchMap((currentUser:any)=>{
                console.log('tada -------',currentUser);
                this.currentUser = currentUser;
                return this.store.getStoreById(currentUser.StoreID)
            }),
            tap((store: any)=>{
                this.currentStore = store;
                if (store && store.Provider && store.Provider.Code === 'RS') {
                    this.api = this.RSProductApi;
                } else {
                    this.api = this.RSProductApi;
                }
            })
        ).subscribe();
        */
    }


    getProductsByIds(ids, storeId?): Observable<any> {
        return this.api.getProductsByIds(ids, storeId);
    }

    getProductByUPC(storeId, pageSize, pageNum, upcList, primaryBarcode = null, subDept1Id?, subDept2Id?, sortBy?, filters?, indexes?, checkUpc?): Observable<any> {
        return this.api.getProductByUPC(storeId, pageSize, pageNum, upcList, primaryBarcode, subDept1Id, subDept2Id, sortBy, filters, indexes, checkUpc);
    }

    getDepartments(refresher?): Observable<any> {
        return this.api.getDepartments(refresher);
    }
    getHealth(product) {
        return this.api.getHealth(product);
    }
}