import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { NavController, ModalController, NavParams } from '@ionic/angular';
import { AuthService, Utils } from '../../../providers';
import { ShoppingList } from '../../../providers/shopping-list/shopping-list';
import { MessagePopup } from '../message-popup/message-popup';
import { enumExpirationType } from 'src/core/enum';
import { AlertPopup } from '../alert-popup/alert-popup';

@Component({
  selector: 'app-offer-detail-popup',
  templateUrl: 'offer-detail-popup.html',
  styleUrls: ['./offer-detail-popup.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OfferDetailPopup implements OnInit {
  public EnumExpirationType = enumExpirationType;

  @Input() offer: any;
  @Input() type: any;
  currentUser: any;
  public expand: boolean = false;
  public upcs: any = [];

  constructor(
    private nav: NavController,
    private modalCtrl: ModalController,
    public shoppingList: ShoppingList,
    private auth: AuthService,
    public utils: Utils,
  ) {
  }

  ngOnInit() {

  }

  ionViewWillEnter() {
    this.offer = JSON.parse(this.offer);
    console.log('offer', this.offer);
    this.getParticipatingProducts();
  }

  async getParticipatingProducts() {
    if (this.offer.ListUPC) {
      this.upcs = this.offer.ListUPC;
      return;
    }
  }

  expandDisclaimer() {
    this.expand = !this.expand;
  }

  addToCard(offer) {
    this.utils.showLoading();
    this.auth.getCurrentUser().then(result => {
      this.currentUser = result;
      this.shoppingList.getActiveShoppingList(this.currentUser).subscribe(activeShoppingList => {
        console.log('getActiveShoppingList', activeShoppingList);
        this.shoppingList.addOfferToCard(offer, this.currentUser, activeShoppingList).subscribe(resulf => {
          if (resulf) {
            this.utils.hideLoading();
            offer.IsAdded = true;
            offer.Reload = true;
            // this.modalCtrl.dismiss(this.offer);
          }
        }, async (error) => {
          this.utils.hideLoading();
          console.log('addOfferToCard error', error);
          var message = error.error.Message || "Something went wrong, please try again!";
          this.alertPopup('Oh No!', message, 'OK');
        });
      });
    });
  }

  async alertPopup(title, message, button = null, tryAgain = null) {
    const modal: HTMLIonModalElement = await this.modalCtrl.create({
      component: AlertPopup,
      componentProps: {
        title: title,
        message: message,
        button: button,
        tryAgain: tryAgain
      },
      // tslint:disable-next-line:quotemark
      cssClass: "alert-popup auto-height"
    });
    await modal.present();
  }

  errorImgHandler(event) {
    event.target.src = "assets/imgs/DefaultMissingImage.jpeg";
  }

  closeModal() {
    this.modalCtrl.dismiss(this.offer);
  }

}
