import { Injectable } from '@angular/core';
import { ENV } from '../../environments/environment';
import { Api } from '../api/api';
import { Utils } from '../utils/utils';
import { Storage } from '@ionic/storage';
import { CacheService } from 'ionic-cache';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUser$: ReplaySubject<any>;
  dxpApiUrl: any;

  constructor(
    private api: Api,
    public storage: Storage,
    private cache: CacheService,
    private router: Router,
    private utils: Utils,
    private events: NgxPubSubService,
  ) {
    this.currentUser$ = <ReplaySubject<any>>new ReplaySubject(1);
    this.dxpApiUrl = 'dxp/api';
  }

  getAuthToken(): string {
    const currentUser = JSON.parse(localStorage.getItem('CurrentApiUser'));
    if (currentUser != null) {
      return currentUser.AccessToken;
    }

    return '';
  }

  refreshToken() {
    const tokenRequest = {
      Username: ENV.APIUserName,
      Password: ENV.APIPassword
    };
    return this.api.post(`${this.dxpApiUrl}/tokens/${ENV.APIComsumerKey}`, tokenRequest);
  }

  getCurrentUser() {
    const key = 'cache-user';
    return this.cache.getItem(key);
  }

  updateCurrentUser(user: any) {
    return new Promise((resolve, reject) => {
      this.cache.saveItem('cache-user', user, undefined, 60 * 60 * 12 * 15).then((data) => {
        resolve(data);
      });
    });
  }
  public authState() {
    return this.currentUser$.asObservable();
  }

  login(accountInfo: any, isSignup = false , initDxp ?: Function ) {
    return new Promise((resolve, reject) => {
      this.api.post(`${this.dxpApiUrl}/login/${accountInfo.username}`, '"' + accountInfo.password + '"').subscribe( async (data: any) => {
        if (data.IsSuccess) {
          data.User.AccessToken = data.Token;
          localStorage.setItem('CurrentApiUser', JSON.stringify(data.User));
          this.events.publishEvent('authChange', data.User);
          if(initDxp) {
            await initDxp(data.User);
          }
          await this.cache.saveItem('cache-user', data.User, undefined, 60 * 60 * 12 * 15)
          if (data.User.IsPwdTemporary) {
            this.utils.hideLoading();
            this.router.navigate(['changePassword']);
          }
          else {
            this.utils.hideLoading();
            if (!isSignup) {
              this.router.navigate(['/']);
            }
          }
        }
        resolve(data);
      },
        (error) => {
          this.utils.hideLoading();
          console.error('ERROR', error);
          reject(error);
        });
    });
  }

  logout() {
    this.events.publishEvent('authChange', null);
    this.utils.segmentContent = '';
    this.utils.pageSlideDigitalCouponIndex = 0;
    this.utils.pageSlideOfferCouponIndex = 0;
    this.currentUser$.next(null)
    localStorage.clear();
    window.postMessage({ action: 'userLogOut' }, '*');
    return this.cache.clearAll() && this.storage.clear();
  }
}

