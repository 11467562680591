import { Injectable, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root'
})
@Pipe({name: 'safeUrl'})
export class SafeUrlPipe {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html) {
    return this.sanitizer.bypassSecurityTrustStyle('url(\'' + encodeURI(html) + '\')');
  }
}
