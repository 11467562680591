import { Injectable } from "@angular/core";
import { Api, AuthService, Store } from "src/providers";
import { Product } from "./product.service";

@Injectable()
export class OnlineProductService extends Product {
    groupCacheKey = 'o-product';
    prefixCacheKey = 'online';
    constructor(
        public api: Api,
        public auth: AuthService,
        public store: Store,
        public rsApi: Api
    ) {
        super(api, auth, store, rsApi);
    }

}