import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { NavController, ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-alert-popup',
  templateUrl: 'alert-popup.html',
  styleUrls: ['./alert-popup.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertPopup implements OnInit {

  @Input() title: any;
  @Input() message: any;
  @Input() moreMessage: any;
  @Input() species: any;
  @Input() button: any;
  @Input() tryAgain: any;

  constructor(
    private modalCtrl: ModalController,
  ) {
  }

  ngOnInit() {

  }

  ionViewWillEnter() {
    console.log('title', this.title);
    console.log('message', this.message);
    console.log('moreMessage', this.moreMessage);
  }


  closeModal(bool) {
    var result: any;

    if (!bool) {
      result = {
        TryAgain: this.tryAgain
      }
    }
    this.modalCtrl.dismiss(result);
  }

}
