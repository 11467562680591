import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'page-idle',
  templateUrl: 'idle.html',
  styleUrls: ['./idle.scss'],
})
export class IdlelPage {
  constructor(
    public router: Router,
    public modalCtrl: ModalController,
  ) {
    
  }
}
