export enum enumCouponsType {
    MFGCoupon = 1,
    MFGOffer = 2,
    MFGReward = 3
}

export enum enumExpirationType {
    AbsoluteExpire = 1,
    AdaptiveExpire = 2,
    QuantityLimit
}