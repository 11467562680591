import { Injectable } from "@angular/core";
import { Api, AuthService, Store } from "src/providers";
import { IProductService } from "./iproduct.service";
import _ from 'lodash';
import { map, tap } from "rxjs/operators";
import { refreshParam } from "src/providers/utils/utils";
import { Observable, of } from "rxjs";

function _getVariants(products) {
    // return _.flatMap(products, 'ProductVariants')
    return products;
}

/** Instore product service */
@Injectable()
export class Product implements IProductService {
    groupCacheKey = 'product';
    prefixCacheKey = 'instore';
    
    currentUser: any;
    currentStore: any;

    constructor(
        public api: Api,
        public auth: AuthService,
        public store: Store,
        public rsApi: Api
    ) {
        this.auth.getCurrentUser().then((currentUser) => {
            console.log('currentUser myAccount', currentUser);
            this.currentUser = currentUser;
            this.store.getStoreById(currentUser.StoreID).subscribe(store => {
                this.currentStore = store;
            }, (error) => {
            });
        }, (error) => {
        });
    }

    search(cri: {
        store?: any,
        keyword?: string,
        deptIds?: any,
        subDept1Id?: [],
        subDept2Id?: [],
        brandIds?: any,
        savings?: any,
        tags?: any,
        onSale?: boolean,
        sort?: any,
        upc?: any,
        upcList?: any,
        itemId?: any,
        checkSumUpc?: boolean,
        pageSize: number,
        pageIndex: number,
        customFields?: any,
        primaryBarcode?: any,
        attrs?: any
    }, cache?: {
        rCache?: any,
        rCacheKey?: any,
        rCacheGroup?: any,

    }) {

        if (!cri.store) {
            cri.store = this.currentStore;
        }

        let params = {
            ExternalStoreID: cri.store && cri.store.ExternalID || '',
            StoreID: cri.store.ExternalID2,
            Keyword: cri.keyword || '',
            PageSize: cri.pageSize,
            PageNum: cri.pageIndex,
            DepartmentId: [],
            UPCList: cri.upcList,
            ItemId: cri.itemId,
            CheckSumUPC: cri.checkSumUpc,
            SortOrder: [],
            BrandName: cri.brandIds,
            SavingsName: cri.savings,
            CustomFields: [],
            PrimaryBarcode: cri.primaryBarcode || true,
            SubDept1Id: [],
            SubDept2Id: []
        };

        // handle dept for pass to DepartmentId, SubDept1Id, SubDept2Id
        _.each(cri.deptIds, (deptId: string) => {
            let deptIds = deptId.split('-');
            if (deptIds[0]) {
                params.DepartmentId.push(deptIds[0])
            }
            if (deptIds[1]) {
                params.SubDept1Id.push(deptIds[1])
            }
            if (deptIds[2]) {
                params.SubDept2Id.push(deptIds[2])
            }
        });

        // handle sort
        if (cri.sort) {
            const sortStrs = cri.sort.split(' '),
                direction = sortStrs[1] || 'DESC';
            let fieldName = sortStrs[0] && sortStrs[0].toLowerCase() || '';

            let sortObj = null;
            if (!fieldName) {
                fieldName = "bestmatch";
            }
            if (this.prefixCacheKey == "instore") {
                switch (fieldName) {
                    case "price":
                        sortObj = {
                            position: 1,
                            sortColumn: 'regPrice',
                            sortDirection: direction
                        };
                        break;
                    case 'onsale':
                        sortObj = {
                            position: 1,
                            sortColumn: 'tprPrice',
                            sortDirection: direction
                        };
                        break;
                    case 'brand':
                        //Brand A-Z
                        sortObj = {
                            position: 1,
                            sortColumn: 'nameForSales',
                            sortDirection: direction
                        };
                        break;
                }
            } else {
                switch (fieldName) {
                    case "bestmatch":
                        sortObj = {
                            sortColumn: 'Relevance',
                        };
                        break;
                    case "price":
                        sortObj = {
                            sortColumn: (direction == "DESC" ? 'UnitPrice' : 'Price'),
                        };
                        break;
                    case 'onsale':
                        sortObj = {
                            position: 1,
                            sortColumn: 'tprPrice',
                            sortDirection: direction
                        };
                        break;
                    case 'brand':
                        sortObj = {
                            sortColumn: 'Brand',
                        };
                        break;
                }
            }
            if (sortObj) {
                params.SortOrder = [sortObj];
            }
        }
        // handle healthFocus
        if (cri.attrs && cri.attrs.length > 0) {
            params.CustomFields = _.map(cri.attrs, (attr) => { return { fieldId: attr, fieldValue: 'Y' } });
        }
        let opts = undefined;
        if (cache) {
            opts = { params: cache };

        }

        return this.api.post('/inventory/product/search/v5', params, opts).pipe(
            tap((rs: any) => {
                // console.log(rs);
                if (rs && rs.Products) {
                    _.each(rs.Products, this._subplmentProductInfo);
                }
            })
        );
    }

    getProductsByIds(ids, storeId?) {
        return this.search({ upcList: ids, pageSize: 0, pageIndex: 0 });
    }

    _subplmentProductInfo(product) {
        if (product.ProductVariants && product.ProductVariants.length == 1) {
            let v = product.ProductVariants[0];
            Object.assign(product, {
                OnSale: v.OnSale,
                Price: v.Price !== null && v.Price !== undefined && v.Price || product.Price,
                SalePrice: v.SalePrice !== null && v.SalePrice !== undefined && v.SalePrice || product.SalePrice,
                PriceText: v.PriceText !== null && v.PriceText !== undefined && v.PriceText || product.PriceText,
                SalePriceText: v.SalePriceText !== null && v.SalePriceText !== undefined && v.SalePriceText || product.SalePriceText
            })
        }
        let brands = product.ProductBrands;
        product.BrandTxt = brands && brands.length > 0 && brands[0].BrandName || '';
        _.each(product.ProductVariants, (v, index) => {
            Object.assign(v, {
                DefaultImage: product.DefaultImage,
                Category: product.Category,
                FullDescription: product.FullDescription,
                ProductTags: product.ProductTags,
                UPC: product.UPC,
                BrandTxt: product.BrandTxt,
                ValueType: product.ValueType,
                FriendlyName: product.FriendlyName
            })
        })
    }

    getProductByUPC(storeId, pageSize, pageNum, upcList, primaryBarcode = null, subDept1Id?, subDept2Id?, sortBy?, filters?, indexes?, checkUpc?) {
        return this.search({ upcList: upcList, pageSize: pageSize, pageIndex: 0 });
    }
    
    getDepartments(refresher?) {
        // return of([1,2,3,4,5,6,7,8,9,10])
        let params = {};
        Object.assign(params, refreshParam(refresher, this.groupCacheKey, null, this.prefixCacheKey));

        return this.rsApi.get('/searchcategories/29', { params: params }).pipe(
            map((rs) => {
                let data = _.filter(rs, (i)=>{
                    return (!i.IsDeleted) && i.Active; 
                })
                return _.orderBy(data, [
                    // i => !!i.Priority,
                    i => {
                        return i.Priority ? i.Priority : 1000
                    }
                ], ['asc']);
            })
        );
    }

    getHealth(product): Observable<any> {
        return of('');
    }
}