import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { NavController, ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-message-popup',
  templateUrl: 'message-popup.html',
  styleUrls: ['./message-popup.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MessagePopup implements OnInit {

  @Input() title: any;
  @Input() message: any;
  @Input() moreMessage: any;
  @Input() species: any;

  // promo code offer
  @Input() textSaving: any;
  @Input() summary: any;
  @Input() expiresDate: any;

  constructor(
    private modalCtrl: ModalController,
  ) {
  }

  ngOnInit() {

  }

  ionViewWillEnter() {
    console.log('title', this.title);
    console.log('message', this.message);
    console.log('moreMessage', this.moreMessage);
  }


  closeModal() {
    this.modalCtrl.dismiss();
  }

}
