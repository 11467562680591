import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Platform } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { ENV  } from '../../environments/environment';
import { MatomoTracker, MatomoInjector } from 'ngx-matomo';
import { CacheService } from 'ionic-cache';
@Injectable({
	providedIn: 'root'
})
export class RSTracker {
	constructor(
		public platform: Platform,
		public device: Device,
		public matomoInjector: MatomoInjector,
		public matomoTracker: MatomoTracker,
		public cache: CacheService,) {
		// console.log('Hello EventLoggerProvider Provider with firebase');
	}
	init(cfg: any) {
		// console.log('inject');
		if (ENV.EnableMatomo) {
			try {
				this.matomoTracker.setCustomUrl('http://' + ENV.AppBundle);
				this.matomoInjector.init(cfg.mUrl, cfg.mId);
				this.matomoTracker.enableLinkTracking(true);
				// this.trackInstallGoal();
			} catch (error) { }

		}
	}
	trackEvent(category: string, action: string, name?: string, value?: any) {
		if (ENV.EnableMatomo) {
			try {
				this.matomoTracker.trackEvent(category, action, name, value)
			} catch (error) { }
		}

	}
	trackUser(userId: any) {
		if (ENV.EnableMatomo) {
			try {
				this.matomoTracker.setUserId(userId);
			} catch (error) { }
		}
	}
	trackUserProperty(id: number, value: any) {
		
		if (ENV.EnableMatomo) {
			try {
				this.matomoTracker.setCustomDimension(id, value);
			} catch (error) { }
		}
	}
	trackGoal(id, value?) {
		if (ENV.EnableMatomo) {
			try {
				this.matomoTracker.trackGoal(id, value);
			} catch (error) { }
		}
	}
	trackPage(pageName: any, url?: any) {
	
		if (ENV.EnableMatomo) {
			try {
				url = url || window.location.pathname;
				console.log('url', url);
				this.matomoTracker.setCustomUrl('http://' + ENV.AppBundle + url);
				this.matomoTracker.trackPageView(pageName);
			} catch (error) { }
		}
	}
	// another detail trackGoal
	async trackInstallGoal() {
		if (ENV.EnableMatomo) {
			try {
				let appVersion = await this.cache.getItem('app_version').catch(() => { return null }),
					cacheTime = 60 * 60 * 24 * 365; // 365 days = year
				// refresh cache time and save new app version
				this.cache.saveItem('app_version', ENV.AppVersion, null, cacheTime);
				if (appVersion == ENV.AppVersion) {
					return;
				}
				this.trackEvent('System', 'Install', ENV.AppVersion, 1);
			} catch (error) { }
		}

		// this.trackGoal(1, ENV.AppVersion);
		// this.trackEvent('System', 'Install', ENV.AppVersion, 1);
	}
}