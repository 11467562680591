import { Api } from '../api/api';
import { RelationshopEComHttpClient } from "../api/rs-ecom-api.service";
import 'rxjs/add/operator/toPromise';

import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs/Observer';

import * as _ from 'lodash';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class Store {
	dxpApiUrl: any;
	storeDxpApiUrl: any;
    systemServiceIndex: any;

	constructor(public api: Api, private cache: CacheService, public apiCore: RelationshopEComHttpClient) {
		this.dxpApiUrl = 'dxp/api';
		this.storeDxpApiUrl = `store/v1.0/api`;
	}

	getAllStore() {
		let rs = this.apiCore.get(`${this.storeDxpApiUrl}/store/company/0/all`);
		
        return rs.pipe( 
            map((dxpStores) => {
                const stores = [];
                _.each(dxpStores, (dxpStore) => {
                    const store = this.convertDXPStoreToStore(dxpStore);
                    store.SystemService = _.orderBy(store.SystemService, ['Priority']);
                    stores.push(store);
                });
                return stores;
            })
        );
	}

	getStoreById(id) {
		const seq = this.apiCore.get(`${this.storeDxpApiUrl}/store/by-id/${id}`, null, null, false, true).pipe(
            map((dxpStore) => {
                // console.log('store', this.convertDXPStoreToStore(dxpStore));
                return this.convertDXPStoreToStore(dxpStore);
            })
        );
        return seq;
	}

	getStoreNearBy(latlng) {
		let rs = this.apiCore.get(`${this.storeDxpApiUrl}/store/company/0/by-coordian?latlng=${latlng}`);
		
        return rs.pipe( 
            map((dxpStores) => {
                const stores = [];
                _.each(dxpStores, (dxpStore) => {
                    const store = this.convertDXPStoreToStore(dxpStore);
                    store.SystemService = _.orderBy(store.SystemService, ['Priority']);
                    stores.push(store);
                });
                return stores;
            })
        );
	}

	getAllState() {
		let seq = this.api.get(`${this.dxpApiUrl}/states`);
		return seq;
	}

	convertDXPStoreToStore(dxpStore) {
        const store: any = {
            CS_StoreID: dxpStore.StoreId,
            // tslint:disable-next-line:radix
            StoreID: parseInt(dxpStore.StoreCode),
            StoreName: dxpStore.Name,
            Address1: dxpStore.Address,
            City: dxpStore.City,
            State: dxpStore.State,
            Zipcode: dxpStore.ZipCode,
            PhoneNumber: dxpStore.Phone,
            Logo: dxpStore.StoreLogo,
            CStoreURL: dxpStore.StoreLogo,
            StoreIconURL: dxpStore.MapPin,
            Latitude: parseFloat(dxpStore.Latitude),
            Longitude: parseFloat(dxpStore.Longitude),
            StoreHours: dxpStore.StoreHour,
            TaxRate: dxpStore.StoreTaxRate,
            Pharmacy: dxpStore.PharmacyFlag,
            PharmacyPhone: dxpStore.PharmacyPhone,
            PharmacyHours: dxpStore.PharmacyHours,
            SystemService: [],
            DepartmentStores: [],
            ServiceStores: []
        };

        if (dxpStore.StoreServices != null && dxpStore.StoreServices.length > 0) {
            _.each(dxpStore.StoreServices, (storeService) => {
                if(!storeService.ServiceName) {
                    let s = this.systemServiceIndex[storeService.ServiceId];
                    if(s && s.length>0){
                        Object.assign(storeService, s[0]);
                    }
                }
                switch (storeService.ServiceName) {
                    case 'eCom':
                        store.ShopOnline = true;
                        store.Pickup = true;
                        break;
                    case 'Pickup':
                        store.ShopOnline = true;
                        store.Pickup = true;
                        break;
                    case 'Delivery':
                        store.ShopOnline = true;
                        store.Delivery = true;
                        break;
                    default:
                        break;
                }
                let serviceSettings = null;
                try {
                    serviceSettings = storeService.SettingJson ? JSON.parse(storeService.SettingJson) : null
                }
                catch (err) {
                    console.log(storeService.SettingJson);
                }
                const systemService = {
                    CS_ServicesID: storeService.ServiceId,
                    DisplayName: storeService.DisplayName,
                    ServiceName: storeService.ServiceName,
                    ServiceSettings: serviceSettings,
                    Priority: storeService.DisplayOrder,
                    IsActive: storeService.IsActive,
                };

                store.SystemService.push(systemService);
            });
        }


        // DepartmentStore
        if (dxpStore.DepartmentStores != null && dxpStore.DepartmentStores.length > 0) {
            _.each(dxpStore.DepartmentStores, (dxpDepartmentStore) => {
                const departmentStore = {
                    CS_StoreID: dxpDepartmentStore.StoreId,
                    CS_DepartmentID: dxpDepartmentStore.DepartmentId,
                    DepartmentSatHours: dxpDepartmentStore.DepartmentSatHours,
                    DepartmentSunHours: dxpDepartmentStore.DepartmentSunHours,
                    DepartmentMFHours: dxpDepartmentStore.DepartmentMFHours,
                    DepartmentPhone: dxpDepartmentStore.DepartmentPhone,
                    DepartmentNote: dxpDepartmentStore.DepartmentNote,
                    DepartmentName: dxpDepartmentStore.DepartmentName,
                    DepartmentIconURL: dxpDepartmentStore.DepartmentIconUrl,
                    DepartmentLinkURL: dxpDepartmentStore.DepartmentLinkUrl,
                    InStoreDepartment: dxpDepartmentStore.InStoreDepartment,
                    IsShowDepartmentIcon: dxpDepartmentStore.IsShowDepartmentIcon,
                    DepartmentHourses: dxpDepartmentStore.DepartmentHourses,
                    Priority: dxpDepartmentStore.DisplayOrder
                };
                if(!store.Pharmacy && dxpDepartmentStore.DepartmentName &&  dxpDepartmentStore.DepartmentName.toLowerCase() == 'pharmacy'){
                    store.Pharmacy = true;
                }

                store.DepartmentStores.push(departmentStore);
            });
        }

        // ServiceStores
        if (dxpStore.InstoreServices != null && dxpStore.InstoreServices.length > 0) {
            _.each(dxpStore.InstoreServices, (instoreService) => {
                const systemService = {
                    CS_ServicesID: instoreService.Id,
                    ServiceName: instoreService.ServiceName,
                    ServiceGroup: instoreService.ServiceGroup,
                    Priority: instoreService.Priority
                };
                store.ServiceStores.push(systemService);
            });
        }

        // BannerId, ExternalId, ExternalId2, ExternalId3
        if (dxpStore.Attributes != null && dxpStore.Attributes.length > 0) {
            let bannerId = 0, cityAreaId = 0, regionId = 0;
            let externalId = '', externalId2 = '', externalId3 = '', typeId = '';;
            _.each(dxpStore.Attributes, (attribute) => {
                if (attribute.Values != null && attribute.Values.length > 0) {
                    switch (attribute.Name) {
                        case 'Banner':
                            // tslint:disable-next-line:radix
                            bannerId = isNaN(attribute.Values[0]) ? bannerId : parseInt(attribute.Values[0]);
                            break;
                        case 'City Area':
                            // tslint:disable-next-line:radix
                            cityAreaId = isNaN(attribute.Values[0]) ? cityAreaId : parseInt(attribute.Values[0]);
                            break;
                        case 'Region':
                            // tslint:disable-next-line:radix
                            regionId = isNaN(attribute.Values[0]) ? regionId : parseInt(attribute.Values[0]);
                            break;
                        case 'Reference Id':
                            externalId = attribute ? attribute.Values[0] : '';
                            break;
                        // case 'United Id':
                        //     externalId2 = attribute ? attribute.Values[0] : '';
                        //     break;
                        // case 'Mi9v8 Id':
                        //     externalId3 = attribute ? attribute.Values[0] : '';
                        //     break;
                        case 'Type':
                            typeId = attribute ? attribute.Values[0] : '';
                            break;
                        default:
                            break;
                    }
                }
            });

            store.CS_BannerID = bannerId;
            store.CS_CityAreaID = cityAreaId;
            store.CS_RegionID = regionId;
            store.ExternalID = externalId;
            store.ExternalID2 = externalId2;
            store.ExternalID3 = externalId3;
            store.TypeId = typeId;
            // cheat for Mi9v8 Store
            if (!store.ExternalId3) {
                store.ExternalID3 = store.StoreID + '';
            }
        }


        return store;
    }

    convertDXPServicesToServices(dxpServices) {
        if (dxpServices == null) {
            return null;
        }

        const services = [];
        _.each(dxpServices, (dxpService) => {
            const service = {
                CS_ServicesID: dxpService.ServiceId,
                ServiceName: dxpService.ServiceName,
                DisplayName: dxpService.DisplayName,
                Priority: dxpService.DisplayOrder,
                ServiceGroup: dxpService.Type,
                IsActive: dxpService.IsActive,
                SettingJson: dxpService.SettingJson
            };

            services.push(service);
        });

        return services;
    }

    convertDXPInstoreServicesToServices(dxpInstoreServices) {
        if (dxpInstoreServices == null) {
            return null;
        }

        const services = [];
        _.each(dxpInstoreServices, (dxpInstoreService) => {
            const service = {
                CS_ServicesID: dxpInstoreService.Id,
                ServiceName: dxpInstoreService.ServiceName,
                Priority: dxpInstoreService.Priority,
                ServiceGroup: dxpInstoreService.ServiceGroup
            };

            services.push(service);
        });

        return services;
    }

    convertDXPCityAreasToCityAreas(dxpCityAreas) {
        if (dxpCityAreas == null) {
            return null;
        }

        const cityAreas = [];
        _.each(dxpCityAreas, (dxpCityArea) => {
            const cityArea = {
                CS_CityAreaID: dxpCityArea.Id,
                CityAreaName: dxpCityArea.CityAreaName,
                Priority: dxpCityArea.Priority,
                IsDeleted: dxpCityArea.IsDeleted
            };

            cityAreas.push(cityArea);
        });

        return cityAreas;
    }

}
