import { throwError as observableThrowError, BehaviorSubject, from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/from';
import { take, filter, catchError, switchMap, finalize, tap, timeout } from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse, HttpClient } 
      from "@angular/common/http";

import { AuthService } from '../authservice/authservice';
import { RequestCache } from './request-cache';
import { ENV  } from '../../environments/environment';

import { Utils } from '../../providers/utils/utils';
import { Device } from '@ionic-native/device/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { Network } from '@ionic-native/network/ngx';
import { LogProvider } from '../logservice/log';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private cache: RequestCache, private utils: Utils, private device: Device, private httpClient: HttpClient, private http: HTTP, public network: Network, private log: LogProvider) {
    this.defaultHeaders = ENV.DefaultHeaders;
  }

  subsriptions = [];
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  defaultHeaders: any;
  detectUrl = ENV.APIDetectUrl;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
    const callback = (isCache = true) => {
      // console.log('TokenInterceptor');.
      return next.handle(this.addTokenToRequest(request, this.authService.getAuthToken(), this.defaultHeaders))
        .pipe(
          timeout(50000),
          catchError(err => {
            console.log(err);
            if (err instanceof HttpErrorResponse) {
              // console.log('ERROR TokenInterceptor', JSON.stringify(err));
              switch ((<HttpErrorResponse>err).status) {
                // case 404:
                //   return this.handle0Error(request, next, err);
                case 0:
                  return this.handle0Error(request, next, err);
                case 401:
                  return this.handle401Error(request, next, this.defaultHeaders);                
                default:
                  return observableThrowError(err);
              }
            } else {
              return observableThrowError(err);
            }
          }),
          tap(event => {
            console.log('request.method', request.method);
            if (event instanceof HttpResponse && request.method === "GET" && isCache && request.url !== this.detectUrl) {
              this.cache.put(request, event);
            }
          })
        );
    };
    // Don't cache if method != GET
    if (request.method !== "GET") {
      return callback();
    }

    // Flag no cache
    if (request.headers.get("no-cache") && request.headers.get("no-cache").toString().toLowerCase() === 'true') {
      this.cache.delete(request);
      return callback(false);
    }

    // Flag to clear cache
    if (request.headers.get("reset-cache") && request.headers.get("reset-cache").toString().toLowerCase() === 'true') {
      this.cache.delete(request);
      return callback();
    }

    // Get from cache && new request if isExpired
    return Observable.from(this.cache.get(request).then(cachedResponse => {
      return cachedResponse ? new HttpResponse(cachedResponse) : callback().toPromise();
    }));
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string, cusHeaders: any): HttpRequest<any> {
    const headers = request.headers;
    // console.log('Header',headers);
    var deviceName = '';
    if((window as any).device){
      deviceName = (window as any).device.name;
    }
    // console.log('Device Name: ' + deviceName);
    deviceName = encodeURI(deviceName);

    if (headers && headers.get('Upload') === 'True') {
      return request.clone({
        setHeaders: {
          "X-MCMAccessToken": token,
          "TrackingCode": cusHeaders['TrackingCode'],
          "DeviceName": deviceName
        }
      });
    } else {
      return request.clone({
        setHeaders: {
          "X-MCMAccessToken": token,
          "Content-Type": 'application/json',
          "TrackingCode": cusHeaders['TrackingCode'],
          "DeviceName": deviceName
        }
      });
    }
  }

  private handle0Error(request: HttpRequest<any>, next: HttpHandler, oriError?) {
    let sendError = '';
    if(ENV.DebugMode === true){
      let errorMsg = JSON.stringify(oriError),
      encodeErrorMsg = encodeURIComponent(errorMsg);
      let isOffLine = this.network.type == this.network.Connection.NONE;
      encodeErrorMsg +=" .NetworkType is "+ this.network.type;
      encodeErrorMsg+=" ."+ this.authService.getAuthToken();
      sendError = `<br/><a href="mailto:${ENV.DebugMail}?body=${encodeErrorMsg}">Send log to support</a>`;

      this.log.err('Request Error', encodeErrorMsg);
    }
    return this.detectInternet().pipe(
      take(1),
      catchError(err => {
        this.utils.customPopup('Internet Connection Failed. Please check the connection and try again.'+sendError, 'popup-no-internet');
        return observableThrowError(new HttpErrorResponse({ error: 'Internet Connection Failed. Please check the connection and try again.', status: 0 }));
      }),
      tap(rs=>{
        this.utils.hideLoading();
        this.utils.customPopup('RSAPI Failed. Please try again later.'+sendError, 'popup-no-internet');
      })
    );
  }

  detectInternet(): Observable<any>{
    console.log('detechInternet');
    return from(this.http.get(ENV.APIDetectUrl, {}, {}));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, headers: any) {

    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authService.refreshToken()
        .pipe(
          switchMap((user: any) => {
            this.tokenSubject.next(user.AccessToken);
            localStorage.setItem('CurrentApiUser', JSON.stringify(user));
            return next.handle(this.addTokenToRequest(request, user.AccessToken, headers));
          }),
          catchError(err => {
            return observableThrowError(err);
          }),
          finalize(() => {
            this.isRefreshingToken = false;
          })
        );
    } else {
      this.isRefreshingToken = false;

      return this.tokenSubject
        .pipe(filter(token => token != null),
          take(1),
          switchMap(token => {
            return next.handle(this.addTokenToRequest(request, token, headers));
          }));
    }
  }
}