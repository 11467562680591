import { switchMap } from 'rxjs/operators';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';

import { of } from 'rxjs';
import { Api, AuthService, Offer, Utils } from 'src/providers';

import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

@Component({
  selector: 'add-offer-promo-code-popup',
  templateUrl: './add-offer-promo-code-popup.html',
  styleUrls: ['./add-offer-promo-code-popup.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class AddOfferPromoCodePopup {
  @Input() promoCode: any;

  currentUser: any;
  // offerPromoCode = "";

  constructor(
    public utils: Utils,
    public offer: Offer,
    public api: Api,
    private modalCtrl: ModalController,
    private barcodeScanner: BarcodeScanner,
    private platform: Platform
  ) { }

  addOfferPromoCode() {
    console.log('offerPromoCode --- 25', this.promoCode);
    this.modalCtrl.dismiss(this.promoCode);
  }

  closeModal(bool) {
    this.modalCtrl.dismiss();
  }

  scanPromoCode() {
    if (!this.platform.is('cordova')) { return; }

    const options = {
      preferFrontCamera: false, // iOS and Android
      showFlipCameraButton: false, // iOS and Android
      showTorchButton: false, // iOS and Android
      torchOn: false, // Android, launch with the torch switched on (if available)
      prompt: 'Hold Steady and Center the Bar Code to Scan Item', // Android
      // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
      resultDisplayDuration: 500,
      // formats: 'QR_CODE,PDF_417', // default: all but PDF_417 and RSS_EXPANDED
      // Android only (portrait|landscape), default unset so it rotates with the device
      orientation: 'portrait',
      disableAnimations: true, // iOS
      disableSuccessBeep: false // iOS
    };
    this.barcodeScanner
      .scan(options)
      .then((data) => {
        if (!data.cancelled) {
          this.promoCode = data.text;
        }
      })
      .catch(async (err) => {
        console.log('error', err);
      });

  }
}
