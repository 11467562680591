import 'rxjs/add/operator/toPromise';

import { Injectable } from '@angular/core';
import { LoadingController, ToastController, AlertController, Events, ModalController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { CacheService } from 'ionic-cache';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';

import { MessagePopup } from '../../pages/modal-popup/message-popup/message-popup';
import { ConfirmPopup } from '../../pages/modal-popup/confirm-popup/confirm-popup';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class Utils {
	_utils: { showWellCome: boolean } = {
		showWellCome: true
	};
	loading: any;
	_store: any;
	_blur: boolean;
	_countDownClock: any;
	_hours: any;
	_minutes: any;
	_seconds: any;
	isLoading = false;
	segmentContent = '';
	pageSlideDigitalCouponIndex = 0;
	pageSlideOfferCouponIndex = 0;
	myParams: any = {};
	isOpenModal: boolean = false;
	offlineMsg = "This page is temporarily unavailable. Please refresh and try again.";

	statelist = [
		{ text: 'Alabama', value: 'AL' },
		{ text: 'Alaska', value: 'AK' },
		{ text: 'Arizona', value: 'AZ' },
		{ text: 'Arkansas', value: 'AR' },
		{ text: 'California', value: 'CA' },
		{ text: 'Colorado', value: 'CO' },
		{ text: 'Connecticut', value: 'CT' },
		{ text: 'Delaware', value: 'DE' },
		{ text: 'District Of Columbia', value: 'DC' },
		{ text: 'Florida', value: 'FL' },
		{ text: 'Georgia', value: 'GA' },
		{ text: 'Hawaii', value: 'HI' },
		{ text: 'Idaho', value: 'ID' },
		{ text: 'Illinois', value: 'IL' },
		{ text: 'Indiana', value: 'IN' },
		{ text: 'Iowa', value: 'IA' },
		{ text: 'Kansas', value: 'KS' },
		{ text: 'Kentucky', value: 'KY' },
		{ text: 'Louisiana', value: 'LA' },
		{ text: 'Maine', value: 'ME' },
		{ text: 'Maryland', value: 'MD' },
		{ text: 'Massachusetts', value: 'MA' },
		{ text: 'Michigan', value: 'MI' },
		{ text: 'Minnesota', value: 'MN' },
		{ text: 'Mississippi', value: 'MS' },
		{ text: 'Missouri', value: 'MO' },
		{ text: 'Montana', value: 'MT' },
		{ text: 'Nebraska', value: 'NE' },
		{ text: 'Nevada', value: 'NV' },
		{ text: 'New Hampshire', value: 'NH' },
		{ text: 'New Jersey', value: 'NJ' },
		{ text: 'New Mexico', value: 'NM' },
		{ text: 'New York', value: 'NY' },
		{ text: 'North Carolina', value: 'NC' },
		{ text: 'North Dakota', value: 'ND' },
		{ text: 'Ohio', value: 'OH' },
		{ text: 'Oklahoma', value: 'OK' },
		{ text: 'Oregon', value: 'OR' },
		{ text: 'Pennsylvania', value: 'PA' },
		{ text: 'Rhode Island', value: 'RI' },
		{ text: 'South Carolina', value: 'SC' },
		{ text: 'South Dakota', value: 'SD' },
		{ text: 'Tennessee', value: 'TN' },
		{ text: 'Texas', value: 'TX' },
		{ text: 'Utah', value: 'UT' },
		{ text: 'Vermont', value: 'VT' },
		{ text: 'Virginia', value: 'VA' },
		{ text: 'Washington', value: 'WA' },
		{ text: 'West Virginia', value: 'WV' },
		{ text: 'Wisconsin', value: 'WI' },
		{ text: 'Wyoming', value: 'WY' }
	];

	cityList = [
		{ City: "Abilene", State: "TX" },
		{ City: "Alamogordo", State: "NM" },
		{ City: "Albuquerque", State: "NM" },
		{ City: "Allen", State: "TX" },
		{ City: "Amarillo", State: "TX" },
		{ City: "Borger", State: "TX" },
		{ City: "Breckenridge", State: "TX" },
		{ City: "Brownfield", State: "TX" },
		{ City: "Brownwood", State: "TX" },
		{ City: "Burkburnett", State: "TX" },
		{ City: "Canyon", State: "TX" },
	];

	featureList = [
		{ text: 'Coffee Shop', value: 'CS' },
		{ text: 'Pharmacy', value: 'P' },
		{ text: 'Fuel', value: 'F' },
		{ text: 'In-store Dining', value: 'ID' },
		{ text: 'Floral', value: 'Fl' },
		{ text: 'Bakery', value: 'B' },
		{ text: 'In-store Bar', value: 'IB' },
		{ text: 'Clear Talk', value: 'CT' },
		{ text: 'Deli', value: 'D' },
		{ text: 'Meat', value: 'M' },
		{ text: 'Red Box', value: 'RB' },
		{ text: 'Bill Pay', value: 'BP' },
		{ text: 'Lottert', value: 'L' }
	];

	urlParsingNode = document.createElement("a");

	lowercase = function (string) { return typeof (string) === 'string' ? string.toLowerCase() : string; };
	msie: any;
	constructor(public loadingCtrl: LoadingController,
		private toastCtrl: ToastController,
		public iab: InAppBrowser,
		private alertCtrl: AlertController,
		public cache: CacheService,
		private geolocation: Geolocation,
		public events: Events,
		private modalCtrl: ModalController) {
		this.msie = parseInt((/msie (\d+)/.exec(this.lowercase(navigator.userAgent)) || [])[1], 10);
		if (isNaN(this.msie)) {
			this.msie = parseInt((/trident\/.*; rv:(\d+)/.exec(this.lowercase(navigator.userAgent)) || [])[1], 10);
		}
	}

	getCities() {
		/*let seq = this.restangular.all('cities').customGET().share();
		seq.subscribe((res: any) => {
			console.log('RESULT CITIES', res);
			if (res.length == 0) {
				res = this.cityList;
			}
		}, err => {
			console.error('ERROR', err);
		});
		*/
		//return seq;
	}

	getStore() {
		return this._store;
	}

	setStore(store) {
		this._store = store;
	}

	async showLoading(opts?) {
		try {
			if (!this.loading) {
				this.isLoading = true;
				this.loading = await this.loadingCtrl.create({
					cssClass: 'loader',
					// duration: 300000,
					duration: opts && opts.duration || 300000,
					spinner: 'circles',
					translucent: false,
				});
				await this.loading.present().then(() => {
					console.log('presented');
					if (!this.isLoading && this.loading) {
						this.loading.dismiss().then(() => console.log('abort presenting'));
						this.loading = null;
					}
				});
			}
		} catch (e) {
			console.log('Show Loading Error', e);
		}
	}

	hideLoading() {
		this.loadingCtrl.getTop().then((isLoading) => {
			if (isLoading) {
				this.loadingCtrl.dismiss().then(() => console.log('dismissed'));
				this.loading = null;
				this.isLoading = false;
			}
		}).catch((error) => {
			console.log('HIDE LOADING ERROR', error);
		});
	}

	// hideLoading() {
	// 	const self = this;
	// 	setTimeout(async function () {
	// 		try {
	// 			if (self.loading) {
	// 				self.loading.dismiss();
	// 				self.loading = null;
	// 			}
	// 		} catch (e) {
	// 			console.log('Hide Loading Error', e);
	// 		}
	// 	}, 300);
	// }

	openInaAppBrowser(url) {
		var option = "location=no,closebuttoncaption=Done,footer=yes,closebuttoncolor=#1A3667";
		this.iab.create(url, '_blank', option);
	}


	getCache(name) {
		var key = 'cache-' + name;
		return this.cache.getItem(key)
			.catch(() => {
				console.log("Cache expired or doesn't exist!");
			});
	}

	setCache(name, data) {
		const self = this;
		self.cache.saveItem(name, data);
	}

	getCurrentPosition() {
		return this.geolocation.getCurrentPosition({ timeout: 10000 }).then((resp) => {
			return {
				isLocationOn: true,
				lat: resp.coords.latitude,
				lng: resp.coords.longitude
			};
		}).catch((error) => {
			console.log('Error getting location', error);
			return {
				isLocationOn: false,
				error: error
			};
		});
	}

	groupBy(data, property) {
		const result = data.reduce((previous, current) => {
			if (!previous[current[property]]) {
				previous[current[property]] = [current];
			} else {
				previous[current[property]].push(current);
			}
			return previous;
		}, {});

		return Object.keys(result).map(key => ({ key, value: result[key] }));
	}

	str2date(str) {
		if (typeof (str) !== 'string') {
			return str;
		}
		str = str ? str.substr(0, 10) : str;
		if (!str) {
			return str;
		}
		var strs = str.split('-');
		return new Date(strs[0], parseInt(strs[1], 10) - 1, strs[2]);
	}
	toast(message) {
		/*
		let toast = this.toastCtrl.create({
			message: message,
			duration: 4000,
			position: 'bottom',
			showCloseButton: true,
			closeButtonText: " X",
			dismissOnPageChange: true
		});
		toast.present();
		*/
	}
	confirmYesNo(title, message, callBack) {
		/*
		let confirm = this.alertCtrl.create({
			title: title,
			message: message,
			buttons: [
				{
					text: 'YES',
					handler: () => {
						callBack(true);
						this.events.publish('pageview:blur', false);
					}
					,
					cssClass: 'btn-yes'
				},
				{ cssClass: 'btn-empty' },
				{
					text: 'NO',
					handler: () => {
						callBack(false);
						this.events.publish('pageview:blur', false);
					},
					cssClass: 'btn-no'
				},

				{
					text: 'x',
					role: 'cancel',
					cssClass: 'btnCancelAlert',
					handler: () => {
						callBack(false);
						this.events.publish('pageview:blur', false);
					}
				}
			],
			cssClass: "cus-alert-modal"
		});
		confirm.present();
		*/
		this.events.publish('pageview:blur', true);
	}
	alert(title, message, callback?) {
		/*
		let alert = this.alertCtrl.create({
			title: title,
			message: message,
			buttons: [
				{
					text: 'OK',
					handler: () => {
						if (callback) {
							callback();
						}
						this.events.publish('pageview:blur', false);
					},
					cssClass: 'btn-ok'
				},
				{
					text: 'x',
					role: 'cancel',
					cssClass: 'btnCancelAlert',
					handler: () => {
						this.events.publish('pageview:blur', false);
					},
				}
			],
			cssClass: "cus-alert-modal cus-alert-message"
		});
		alert.present();
		*/
		this.events.publish('pageview:blur', true);
	}
	formatDate(date) {
		var weekday = new Array(7);
		weekday[0] = "Sunday";
		weekday[1] = "Monday";
		weekday[2] = "Tuesday";
		weekday[3] = "Wednesday";
		weekday[4] = "Thursday";
		weekday[5] = "Friday";
		weekday[6] = "Saturday";

		var monthNames = [
			"Jan", "Feb", "Mar",
			"Apr", "May", "Jun", "Jul",
			"Aug", "Sept", "Oct",
			"Nov", "Dec"
		];
		return [weekday[date.getDay()], monthNames[date.getMonth()] + '. ' + date.getDate()]
	}
	openInApp(url) {
		if (!url) {
			return null;
		}
		return this.iab.create(url, '_blank', 'location=no,enableViewportScale=yes,closebuttoncaption=Close,toolbarposition=bottom,footer=yes,clearsessioncache=yes,clearcache=yes');
	}
	encodeUriQuery(val, pctEncodeSpaces?) {
		return encodeURIComponent(val).
			replace(/%40/gi, '@').
			replace(/%3A/gi, ':').
			replace(/%24/g, '$').
			replace(/%2C/gi, ',').
			replace(/%20/g, (pctEncodeSpaces ? '%20' : '+'));
	}
	urlResolve(url, base?) {
		var href = url;

		if (this.msie) {
			// Normalize before parse.  Refer Implementation Notes on why this is
			// done in two steps on IE.
			this.urlParsingNode.setAttribute("href", href);
			href = this.urlParsingNode.href;
		}

		this.urlParsingNode.setAttribute('href', href);

		// urlParsingNode provides the UrlUtils interface - http://url.spec.whatwg.org/#urlutils
		return {
			href: this.urlParsingNode.href,
			protocol: this.urlParsingNode.protocol ? this.urlParsingNode.protocol.replace(/:$/, '') : '',
			host: this.urlParsingNode.host,
			search: this.urlParsingNode.search ? this.urlParsingNode.search.replace(/^\?/, '') : '',
			hash: this.urlParsingNode.hash ? this.urlParsingNode.hash.replace(/^#/, '') : '',
			hostname: this.urlParsingNode.hostname,
			port: this.urlParsingNode.port,
			pathname: (this.urlParsingNode.pathname.charAt(0) === '/') ? this.urlParsingNode.pathname : '/' + this.urlParsingNode.pathname
		};
	}

	encodeUrl(obj) {
		const parts = [],
			self = this;
		_.forEach(obj, function (value, key) {
			if (_.isArray(value)) {
				_.forEach(value, function (arrayValue) {
					parts.push(self.encodeUriQuery(key, true) +
						(arrayValue === true ? '' : '=' + self.encodeUriQuery(arrayValue, true)));
				});
			} else {
				parts.push(self.encodeUriQuery(key, true) +
					(value === true ? '' : '=' + self.encodeUriQuery(value, true)));
			}
		});
		return parts.length ? parts.join('&') : '';
	}

	removeSpecialChars(s) {
		if (s) {
			return s.replace(/[^a-z0-9]/ig, '')
		}

		return "";
	}

	async alertPopup(text) {
		const modal: HTMLIonModalElement = await this.modalCtrl.create({
			component: MessagePopup,
			componentProps: {
				title: '',
				message: `<p class="custom-message">${text}</p>`,
			},
			// tslint:disable-next-line:quotemark
			cssClass: "popup-no-email"
		});
		await modal.present();
		this.hideLoading();
	}

	async alertError(text) {
		const modal: HTMLIonModalElement = await this.modalCtrl.create({
			component: MessagePopup,
			componentProps: {
				title: 'Oh No!',
				message: `<p class="custom-message">${text}</p>`,
			},
			// tslint:disable-next-line:quotemark
			cssClass: "popup-no-email"
		});
		await modal.present();
		this.hideLoading();
	}

	async customPopup(text, cssClass) {
		if (this.isOpenModal)
			return;

		this.isOpenModal = true;

		const modal: HTMLIonModalElement = await this.modalCtrl.create({
			component: MessagePopup,
			componentProps: {
				title: '',
				message: `<p class="custom-message">${text}</p>`,
			},
			// tslint:disable-next-line:quotemark
			cssClass: cssClass
		});

		modal.onDidDismiss().then(() => {
			console.log('dismiss');
			this.isOpenModal = false;
		});

		await modal.present();
		this.hideLoading();
	}
}
export function refreshParam(refresher, groupkey?, key?, prefixKey?) {
	return { rCache: refresher ? 'fresh' : 'default', rCacheKey: key || null, rCacheGroupKey: groupkey || null, rPrefixCacheKey: prefixKey || null };
}