import { EnvironmentModel } from './environment.model';

class EnvironmentImpl extends EnvironmentModel {
 
  public readonly production = false;
  public readonly Mode = "Staging";
  public readonly APIUrl = 'https://stgbigyapi.relationshop.net/v6';
  public readonly APIDetectUrl = 'https://google.com/blank.html';
  public readonly ApplicationId = ''; // isAndroid() ? '56dd18ee-abe1-4dae-8737-bdf0d64898a7' = 'f3315060-5099-41df-809a-9c11e6e36d59';
  public readonly EComHost = 'https://stgbigy-api.relationshop.net';
  public readonly ScriptUrl = 'https://stgbigy-cloud.relationshop.net/dxp';
	public readonly DxpComponentBuildNumber = '70';
 
  public readonly APIComsumerKey = 'F0BF6149-8EAA-4B3A-A409-86106C379BED';
  public readonly APIUserName = 'BigYRSApiUser';
  public readonly APIPassword = 'BigYRS0123';
  public readonly DebugMode = false;
  public readonly DebugMail ='trang@relationshop.com';

	public readonly DefaultImg: string = 'https://bigycloud.relationshop.net/RSData/DefaultMissingImage.jpg';
}

export const ENV: EnvironmentModel = new EnvironmentImpl();
