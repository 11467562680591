import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

// import { Utils } from '@rsApp/modules/utils/providers/utils';
// import { Credential } from '@rsApp/modules/auth/providers/credential.service';
// import { ShoppingList } from '../../providers/shopping-list.service';
import _ from 'lodash';
import { ENV } from 'src/environments/environment';
import { AppSettings } from 'src/providers';


@Component({
	selector: 'product-item',
	templateUrl: 'product-item.html',
	styleUrls: ['product-item.scss']
})
export class ProductItem implements OnChanges {
	@Input() product: any
	@Input() mainCtrl: any
	defaultImg = ENV.DefaultImg;
	defaultImageSaleTagUrl = '';
	constructor(
		public appSetting: AppSettings
	) {
		// setTimeout(()=>{
		// 	this.product.ShortDescription += "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras accumsan";
		// }, 10);	

	}
	ionViewDidEnter() {
	}
	ngOnInit() {
		this.loadDefaultImageSaleTagUrl();
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['product']) {
			if (!this.product.CurrentVariant && this.product.ProductVariants && this.product.ProductVariants.length > 0 && this.mainCtrl.disableSize != true) {
				//base on disableSize  is trick code, will ask Trang Dang
				this.product.CurrentVariant = this.product.ProductVariants[0];
			}
			// handle show price
			if ((this.product.SalePriceText && this.product.SalePriceText.toLowerCase().indexOf('buy') > -1) || (!this.product.Price && !this.product.PriceText && this.product.OnSale)) {
				this.product.IsShowPrice = false;
			}

			//handle sale note
			if(this.product.UnitPrice && this.product.UnitPrice.toLowerCase().includes('sale-note')) {
				let unitPriceSplit = this.product.UnitPrice.split("<br>");
				this.product.UnitPrice = unitPriceSplit[0].trim();
				this.product.SalePriceText += ` <br>${unitPriceSplit[1]}`;
			}
		}
	}

	loadDefaultImageSaleTagUrl() {
		this.appSetting.getSaleTagImage().subscribe(imageUrl => {
			this.defaultImageSaleTagUrl = imageUrl;
		});
	}

	ionError(){
		console.log("ionError");
	}

	preventBuble(event) {
		if (!event) {
			return;
		}
		if (event.stopPropagation) {
			event.stopPropagation();
		}
		if (event.preventDefault) {
			event.preventDefault();
		}
		if (!event.srcEvent) {
			return;
		}
		let srcEvent = event.srcEvent;
		if (srcEvent.stopPropagation) {
			srcEvent.stopPropagation();
		}
		if (srcEvent.preventDefault) {
			srcEvent.preventDefault();
		}
	}
}
