import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DigitalCouponDetailPopup } from './digital-coupon-detail-popup';
import { ProductModule } from 'src/pages/product/product.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: DigitalCouponDetailPopup
      }
    ]),
    ProductModule
  ],
  declarations: [DigitalCouponDetailPopup]
})
export class DigitalCouponDetailPopupModule {}
