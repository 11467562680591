import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { NavController, ModalController, NavParams } from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ENV  } from '../../../environments/environment';
import { RSTracker } from '../../../providers';

@Component({
  selector: 'app-set-store-popup',
  templateUrl: 'set-store-popup.html',
  styleUrls: ['./set-store-popup.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class SetStorePopup implements OnInit {
  formSetStore: FormGroup;
  errorMsg: string = "";

  constructor(private modalCtrl: ModalController, public formBuilder: FormBuilder, private tracker: RSTracker) {
    this.formSetStore = formBuilder.group({
      storeid: ['', Validators.required],
      pincode: ['', Validators.required]
    });
  }

  ngOnInit() {

  }

  ionViewWillEnter() {

  }

  setStore(){
    console.log('setStore');
    if (this.formSetStore.valid){
      var storeId = this.formSetStore.value.storeid;
      var pinCode = this.formSetStore.value.pincode;

      if(pinCode == ENV.Pincode){
        this.errorMsg = "";
        this.tracker.trackUserProperty(1, storeId);
        this.closeModal(true);
      }else{
        this.errorMsg = "Pincode incorrect";
      }

    }
    
  }

  closeModal(bool) {
    this.modalCtrl.dismiss(bool);
  }

}
