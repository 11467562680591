import { Api } from '../api/api';
import 'rxjs/add/operator/toPromise';

import { Injectable } from '@angular/core';
import { CacheService } from 'ionic-cache';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs/Observer';
import { ENV } from '../../environments/environment';

import * as _ from 'lodash';

@Injectable()
export class DigitalCoupon {
	dxpApiUrl: any;

	constructor(public api: Api, private cache: CacheService) {
		this.dxpApiUrl = 'dxp/api';
	}

	getAllDigitalCoupon(user, refresher?) {
		const self = this;
		const url = 'digital-coupons';

		var reqUrl = `${this.dxpApiUrl}/mfr-digital-coupons?bannerId=${ENV.DefaultBanerId}&username=${user.Email}`;
		// return req;
		const funcRespone = function (obs: Observer<any>) {
			// tslint:disable-next-line:max-line-length
			const req = self.api.get(reqUrl, null, null, true);
			self.cache.removeItem(url);
			self.cache.loadFromObservable(url, req, url, 3600).subscribe(result => {
				obs.next(result);
				obs.complete();
			});
		}
		return new Observable((obs: Observer<any>) => {
			this.cache.getItem(url).then(result => {
				if (result && result.length > 0 && !refresher) {
					obs.next(result);
					obs.complete();
				} else {
					funcRespone(obs);
				}
			}).catch((error) => {
				funcRespone(obs);
			});
		});
	}

    getDealDetailById(dealId , user) {
        const url = `${this.dxpApiUrl}/mfr-digital-coupons/${dealId}?username=${user.UserName}`;
        return this.api.get(url);
    }


	// addOfferToCard(coupon){
	// 	const body = {
	// 		'couponId': coupon.Id,
	// 		'couponType': coupon.CpnType,
	// 		'listId': '',
	// 		'provider': '',
	// 		'deviceId': '',
	// 		'catID': coupon.Cats[0].CS_CategoryId,
	// 		'quantity': coupon.ItmQty,
	// 		'bannerID': 30
	// 	};
	// 	return this.api.post('cards/' + '5830614' + '/coupon/v4', body);
	// }
}
