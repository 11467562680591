import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';


const routes: Routes = [
  { path: '', redirectTo: 'rewards', pathMatch: 'full' },
  // { path: 'home', loadChildren: '../pages/home/home.module#HomePageModule', canActivate: [AuthGuard] },
  // tslint:disable-next-line:max-line-length
  { path: 'rewards', loadChildren: '../pages/rewards/rewards.module#RewardsPageModule', canActivate: [AuthGuard] },
  { path: 'rewards/:slug', loadChildren: '../pages/rewards/rewards.module#RewardsPageModule', canActivate: [AuthGuard] },
  { path: 'offers', loadChildren: '../pages/offers/offer.module#OffersPageModule', canActivate: [AuthGuard] },
  // tslint:disable-next-line:max-line-length
  { path: 'digitalCoupons', loadChildren: '../pages/digital-coupons/digital-coupons.module#DigitalCouponsPageModule', canActivate: [AuthGuard] },
  { path: 'digitalCouponDetail', loadChildren: '../pages/digital-coupon-detail/digital-coupon-detail.module#DigitalCouponDetailPageModule', canActivate: [AuthGuard] },
  { path: 'onMyCard', loadChildren: '../pages/my-card/my-card.module#MyCardPageModule', canActivate: [AuthGuard] },
  { path: 'account', loadChildren: '../pages/account/account.module#AccountPageModule', canActivate: [AuthGuard] },
  { path: 'signup', loadChildren: '../pages/signup/signup.module#SignupPageModule' },
  { path: 'signup/:page/:accountType', loadChildren: '../pages/signup/signup.module#SignupPageModule' },
  { path: 'signin', loadChildren: '../pages/signin/signin.module#SigninPageModule' },
  { path: 'changePassword', loadChildren: '../pages/change-password/change-password.module#ChangePasswordPageModule', canActivate: [AuthGuard] },
  { path: 'onMyGas', loadChildren: '../pages/my-gas/my-gas.module#MyGasPageModule', canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  // imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
