import { Api } from '../api/api';
import { Observable, Observer, of } from 'rxjs';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AppSettings {
    public data;
    saleTagImage = '';
    groupCacheKey = 'setting';
    dxpApiUrl: any;
    cmsApiUrl: any;

    constructor(public api: Api) {
        this.dxpApiUrl = 'dxp/api';
        this.cmsApiUrl = 'cms/api';
    }

    getSettings() {
        const self = this;
        const url = `${this.dxpApiUrl}/settings`;
        return self.api.get(url);
    }

    // legacy 
    
    // getSettingByName(name) {
    //     return new Observable((obs: Observer<any>) => {
    //         this.getSettings().subscribe((result: any) => {
    //             _.forEach(result, function (setting) {
    //                 if (setting.SettingName === name) {
    //                     obs.next(setting);
    //                     obs.complete();
    //                 }
    //             });
    //         });
    //     });
    // }
    
    getSettingByName(name) {
        return this.api.get(`${this.dxpApiUrl}/setting/${name}`, null, null, false, true);
    }

    public getSaleTagImage(refresher?) {
        let params = {};
        if (this.saleTagImage) {
            return of(this.saleTagImage);
        }
        const url = `${this.dxpApiUrl}/setting/DEFAULT_IMAGE_SALE_TAG_URL`;
        Object.assign(params, refreshParam(refresher, this.groupCacheKey));
        let o = this.api.get(url, { params: params }).pipe(
            catchError((e) => {
                console.log('getTakeOutServiceSetting error');
                return of('');
            }),
            map((settingResult: any) => {
                this.saleTagImage = settingResult;
                return settingResult;
            }));
        return o;
    }

    public getSettingValue(settingName) {
        let settings = this.getSettings().pipe(
          map((data) => {
            var setting = _.find(data, (o: any) => (o.SettingName == settingName))
            if (setting) {
              return setting.SettingValue;
            }
            return '';
          })
        )
        return settings;
      }
}

function refreshParam(refresher, groupkey?, key?, prefixKey?) {
    return { rCache: refresher ? 'fresh' : 'default', rCacheKey: key || null, rCacheGroupKey: groupkey || null, rPrefixCacheKey: prefixKey || null };
}
