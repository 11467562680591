import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ProductServiceModule } from './product-services.module';
import { ProductList } from './components/product-list/product-list';
import { ProductItem } from './components/product-item/product-item';
import { TruncatePipe } from 'src/providers/utils/pipes/truncate/truncate';
import { ProductDetailPage } from './components/product-detail/product-detail';
import { ShareModule } from 'src/utils/share/share.module';


export { ProductItem, ProductDetailPage };

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		ProductServiceModule,
		ShareModule.forRoot()
	],
	entryComponents: [ProductList, ProductItem, ProductDetailPage],
	exports: [ProductList, ProductItem, ProductDetailPage, TruncatePipe],
	declarations: [ProductList, ProductItem, ProductDetailPage, TruncatePipe],
	providers: []
})
export class ProductModule {

}